
import CreateTabs from "../Create/createTabs.vue";
import Overview from "../Create/overview.vue";
import Vue from "vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
import {
	AdvertiserFilters,
	AdvertiserOptions,
} from "../../../../interfaces/advertiser";
import { initCampaign } from "../../../../utils/initData";
import Alertize from "../../../../components/Alertize.vue";
import { isEmpty, isNull, isUndefined } from "lodash";
import { Campaign } from "../../../../interfaces/campaign";
// @ts-ignore
import NavModifiersModels from "@/views/Admin/Campaigns/Dependencies/TabNavigation.vue";
// @ts-ignore
import BidModelContent from "@/views/Admin/Campaigns/Dependencies/Contents/bidModel.vue";
// @ts-ignore
import BidModifierContent from "@/views/Admin/Campaigns/Dependencies/Contents/bidModifier.vue";
// @ts-ignore
import DeliveryModelContent from "@/views/Admin/Campaigns/Dependencies/Contents/deliveryModel.vue";
// @ts-ignore
import DeliveryModifierContent from "@/views/Admin/Campaigns/Dependencies/Contents/deliveryModifier.vue";
import {
	Notification,
	MessageTypes,
	// @ts-ignore
} from "@/interfaces/proccess";
import { mapActions } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: "Edit",
	props: {},
	components: {
		CreateTabs,
		Overview,
		Alertize,
		NavModifiersModels,
		BidModelContent,
		BidModifierContent,
		DeliveryModelContent,
		DeliveryModifierContent,
	},
	data: () => ({
		title: "Create",
		currentTab: 0,
		items: ["Overview", "Modifiers / Models"],
		account: {
			currency: {
				name: "USD",
			},
			timezone: {
				name: "UTC",
			},
		},
		campaign: initCampaign(),
		campaignTemp: {},
		start_time: "00:00:00",
		end_time: "23:59:59",
		date_format: "YYYY-MM-DD",
		time_format: "HH:mm:ss",
		optimization: 0,
		updateCountBidModifier: true,

		// Tab Modifiers and Models
		currentTabModifiers: 0,

		success: {
			type: MessageTypes.SUCCESS,
			title: i18n.t("title-success"),
			message: i18n.t("success"),
			btn_text: i18n.t("continue"),
			show: true,
			to: undefined,
			btn_text_redirect: i18n.t('create_new_line'),
    		to_redirect: "LineItemCreate",
    		show_redirect: false,
			query: undefined,
			params: undefined
		} as Notification,

		fieldLabel: {
			daily_budget: 'Daily Budget'
		},

		resources:{
            continents:[],
			types_frecuency_caps:[]
        },
	}),
	async created() {
		await this.handleShow(this.getID);
		this.campaign = await this.getParsedData();
		this.optimization = this.campaign.optimization_strategy_id;
	},
	async mounted() {
		this.dispatchAccount();
		this.dispatchBudgetTypes();
		this.dispatchAdvertisers();
		this.dispatchKpiCampaigns();
		this.dispatchStrategies();
		this.dispatchTraffickers();
		this.dispatchCampaignPacing();
		this.dispatchOptimizationStrategies();
		this.dispatchTypesFrecuencyCaps();
		await this.dispatchContinents();
		if (Array(this.campaign.frequency_caps).length) {
			this.dispatchUnitTimes();
		}
	},
	computed: {
		getCampaign() {
			return this.$store.state.campaign.campaign;
		},
		getID(): Number {
			return Number(this.$route.params.id);
		},
		hasID() {
			return !!this.$route.params.id;
		},
		getAccount() {
			return this.$store.state.account.account;
		},
		getBudgetTypes() {
			return this.$store.state.custom_list.budget_types;
		},
		getAdvertisers() {
			return this.$store.state.advertiser.advertisers_list;
		},
		getCampaignsPacing() {
			return this.$store.state.custom_list.campaigns_pacing;
		},
		getOptimizationStrategies() {
			return this.$store.state.custom_list.optimization_strategies;
		},
		getKpiCampaigns() {
			return this.$store.state.custom_list.kpi_campaigns;
		},
		getStrategies() {
			return this.$store.state.custom_list.strategies;
		},
		getUnitTimes() {
			return this.$store.state.custom_list.unit_times;
		},
		getTraffickers() {
			return this.$store.state.user.traffickers;
		},
		getModifier() {
			return this.$store.state.modifier.modifier;
		},
		getContinens(){
			return this.resources.continents;
		},
		getFrecuencyTypes(){
			return this.resources.types_frecuency_caps;
		},
		getItems() {
			this.items = [
				{
					key: 0,
					label: "Overview",
					disabled: false,
				},
				{
					key: 1,
					label: "Modifiers / Models",
					disabled: !this.isCreatedEntity,
				},
			];
			return this.items;
		},
		isCreatedEntity() {
			return this.hasData(this.getCampaign.id);
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("campaign", [
			"continentsList",
		]),

		handleCancelModifier() {
			this.$router.push({ name: "CampaignsIndex" });
		},

		async getParsedData() {
			let campaign = this.getCampaign;
			await this.cloneCampaign(campaign);
			//campaign.automatic_allocation = campaign.automatic_allocation ? 1 : 0;

			this.start_time = this.formatDate(campaign.start_date, this.time_format);
			this.end_time = this.formatDate(campaign.end_date, this.time_format);

			// campaign.start_date = this.formatDate(
			// 	campaign.start_date,
			// 	this.date_format
			// );
			// campaign.end_date = this.formatDate(
			// 	campaign.end_date,
			// 	this.date_format
			// );

			return campaign;
		},
		formatDate(date: String, format: String) {
			return this.moment(date).format(format);
		},
		updateSelectedTabIndex(index: number) {
			this.currentTab = index;
		},
		currentTabSelected(item: String, selected: String) {
			return item === selected;
		},
		updateModelOverview(model: any) {
			this.campaign = model;
		},

		/**
		 * GET
		 */
		async dispatchContinents(){
            const response = await this.continentsList();
            Object.entries(response).forEach(([key, valor]) => {
                let obj = {
                    id: valor,
                    value: key
                }
                this.resources.continents.push(obj);
            });
        },

		async dispatchAdvertisers() {
			return this.$store.dispatch("advertiser/list", {
				filters: {} as AdvertiserFilters,
				options: { sort: "name", order: "asc" } as AdvertiserOptions,
			});
		},

		async dispatchAccount() {
			return this.$store.dispatch("account/getAccount", {
				root: true,
			});
		},

		async dispatchBudgetTypes() {
			return this.$store.dispatch("custom_list/getBudgetTypes", {
				root: true,
			});
		},

		async dispatchCampaignPacing() {
			return this.$store.dispatch("custom_list/getCampaignPacing", {
				root: true,
			});
		},

		async dispatchOptimizationStrategies() {
			return this.$store.dispatch("custom_list/getOptimizationStrategies", {
				root: true,
			});
		},

		async dispatchKpiCampaigns() {
			return this.$store.dispatch("custom_list/getKpiCampaigns", {
				root: true,
			});
		},

		async dispatchStrategies() {
			return this.$store.dispatch("custom_list/getStrategies", {
				root: true,
			});
		},

		async dispatchUnitTimes() {
			if (!isEmpty(this.getUnitTimes)) return;
			return this.$store.dispatch("custom_list/getUnitTimes", {
				root: true,
			});
		},

		async dispatchTraffickers() {
			return this.$store.dispatch(
				"user/traffickers",
				{ filters: { active: true }, options: {} },
				{ root: true }
			);
		},
		async dispatchTypesFrecuencyCaps() {
			if (!isEmpty(this.resources.types_frecuency_caps))
				return this.resources.types_frecuency_caps;
			this.$store.dispatch("custom_list/getTypesFrecuencyCaps")
			.then((res) =>{
				this.resources.types_frecuency_caps = res;
			})
			.catch((err) => {
				this.resources.types_frecuency_caps = [];
			});
		},
		async show(id: number) {
			// if (!id) return;
			return this.$store.dispatch("campaign/show", id, {
				root: true,
			});
		},

		async handleShow(id: Number) {
			try {
				await this.setLoadingData(TypeLoading.loading);
				await this.show(id);
				await this.setLoadingData();
			} catch (error) {
				console.error("handleShow", { error: error });
				await this.setLoadingData();
			}
		},

		async handleSubmit(params: {
			method: string;
			continue: Boolean;
			data: any;
		}) {
			try {
				await this.setLoadingData(TypeLoading.loading);
				if (params.continue) {
					await this.update({
						id: this.getID,
						continue: params.continue,
						data: params.data,
					});
					this.success.show = true;
					this.success.to = "";
					this.success.show_redirect = true;
					this.success.query = { campaign_id: this.getID};
					await this.setNotification(this.success);
					this.updateSelectedTabIndex(1);
					//this.setNotification(this.success);
					//this.$router.push({ name: 'LineItemCreate', query: { campaign_id: response.id } })
				} else {
					await this.update({
						id: this.getID,
						continue: params.continue,
						data: params.data,
					});
					this.success.show = true;
					this.success.to = "CampaignsIndex";
					this.success.show_redirect = true;
					this.success.query = { campaign_id: this.getID};
					this.setNotification(this.success);
					//this.$router.push({ name: 'CampaignsIndex' });
				}
				await this.setLoadingData();
			} catch (error) {
				//console.error("handleSubmit", { error: error });
				await this.setLoadingData();
			}
		},

		async update(params: {
			method: string;
			continue: Boolean;
			data: Campaign;
		}) {
			return this.$store.dispatch("campaign/update", params);
		},

		updateSelectedTabModifiersIndex(index: number) {
			this.currentTabModifiers = index;
		},

		async handleSubmitModifier(params) {
			try {
				await this.setLoadingData(TypeLoading.loading);
				const result = await this.dispatchCreateModifier(
					params.entity,
					params.isBidModifier
				);
				this.getCampaign.modifier_id = result.id;
				this.updateCountBidModifier = false;
				if (isUndefined(result) || isNull(result) || isEmpty(result)) {
					this.setNotification({
						title: this.$t("title-failed"),
						message: this.$t("failed"),
						btn_text: this.$t("try-again"),
						type: MessageTypes.ERROR,
						show: true,
					});
				} else {
					this.setNotification({
						title: this.$t("title-success"),
						message: this.$t("success"),
						btn_text: this.$t("continue"),
						type: MessageTypes.SUCCESS,
						show: true,
					});
					await params.executeDispatchFunction();
					await params.executeClearFunction();
				}
			} catch (error) {
				//console.error("handleSubmitModifier", { error: error });
			}
			await this.setLoadingData();
		},

		async handleCreateAssociation(params) {
			try {
				await this.setLoadingData(TypeLoading.loading);
				const result = await this.dispatchCreateAssociation(
					params.module,
					params.entity
				);
				if (!isUndefined(result) && !isNull(result) && !isEmpty(result)) {
					if (params.continue) {
						this.success.to = undefined;
						this.setNotification(this.success);
						//this.$router.push({ name: "LineItemCreate" });
						this.$router.push({
							name: "LineItemCreate",
							query: { campaign_id: params.entity.campaign_id },
						});
					} else {
						this.success.show = true;
						this.success.show_redirect = true;
						this.success.query = { campaign_id: this.getID};
						this.success.to = "CampaignsIndex";
						this.setNotification(this.success);
						//this.$router.push({ name: "CampaignsIndex" });
					}
				}
			} catch (error) {
				console.error("handleCreateAssociation", { error: error });
			}
			await this.setLoadingData();
		},

		async dispatchCreateAssociation(module: string, entity: any) {
			let result: any = undefined;
			switch (module) {
				case "BID_MODIFIER":
					result = await this.dispatchCreateAssociationWithBidModifier(entity);
					break;
				case "DELIVERY_MODIFIERS":
					result = await this.dispatchCreateAssociationWithBidModifier(entity);
					break;
			}
			return result;
		},

		async dispatchCreateModifier(entity: any, isBidModifier: Boolean = false) {
			return this.$store.dispatch(
				"modifier/createModifier",
				{
					modifier: entity,
					isBidModifier: isBidModifier,
				},
				{
					root: true,
				}
			);
		},

		async dispatchCreateAssociationWithBidModifier(entity: any) {
			return await this.$store.dispatch(
				"campaign/createAssociationWithModifier",
				{ entity: entity },
				{ root: true }
			);
		},

		setNotification(notification: Notification) {
			return this.$store.dispatch("proccess/setNotification", notification, {
				root: true,
			});
		},

		hasData(entity: any) {
			return !isUndefined(entity) && !isNull(entity);
		},

		handleLabel(event: { key: string; value: any; }) {
			this.fieldLabel[event.key] = event.value;
		},

		async cloneCampaign(data: any){
			let keysData = Object.keys(data);
			keysData.forEach((k) => {
				this.campaignTemp[k] = data[k];
			});
		},
	},

	watch: {},
});
